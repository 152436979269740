import React from "react"
// import simpleMenuTree from "../../components/dashibiden/dynamicMenu/__fixtures__/simpleMenuTree";
// import DashMenu from '../../components/dashorm/menuHelpers/DashMenu.js';

// local test url : http://localhost:1111/devTests/simpleAppMenuTest/

export default function simpleAppMenuTest() {
  return (
    <div>
      <p>@TODO: result of "big migration", restore or delete it</p>
      {/* <h1>Tree to test : </h1>
      <DashMenu menuTree={simpleMenuTree} /> */}
    </div>
  )
}
